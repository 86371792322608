import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.json.stringify.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadPicture = function uploadPicture() {
  return import("../common/uploadPicture");
};

var proButton = function proButton() {
  return import("../common/proButton");
};

export default {
  components: {
    uploadPicture: uploadPicture,
    proButton: proButton
  },
  data: function data() {
    return {
      resourceTypes: [{
        label: "友链",
        value: "friendUrl"
      }, {
        label: "恋爱图片",
        value: "lovePhoto"
      }, {
        label: "音乐",
        value: "funny"
      }, {
        label: "收藏夹",
        value: "favorites"
      }],
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        resourceType: "",
        status: null
      },
      resourcePaths: [],
      coverDialog: false,
      uploadDialog: false,
      addResourcePathDialog: false,
      isUpdate: false,
      resourcePath: {
        title: "",
        classify: "",
        introduction: "",
        cover: "",
        url: "",
        type: "",
        remark: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getResourcePaths();
  },
  mounted: function mounted() {},
  methods: {
    addPicture: function addPicture(res) {
      this.resourcePath.cover = res;
      this.coverDialog = false;
    },
    addFile: function addFile(res) {
      this.resourcePath.url = res;
      this.uploadDialog = false;
    },
    addResourcePathUrl: function addResourcePathUrl() {
      if (this.addResourcePathDialog === false) {
        return;
      }

      if (!['funny'].includes(this.resourcePath.type)) {
        this.$message({
          message: "请选择有效资源类型！",
          type: "error"
        });
        return;
      }

      this.uploadDialog = true;
    },
    addResourcePathCover: function addResourcePathCover() {
      if (this.addResourcePathDialog === false) {
        return;
      }

      if (this.$common.isEmpty(this.resourcePath.type)) {
        this.$message({
          message: "请选择资源类型！",
          type: "error"
        });
        return;
      }

      this.coverDialog = true;
    },
    addResourcePath: function addResourcePath() {
      var _this = this;

      if (this.$common.isEmpty(this.resourcePath.title) || this.$common.isEmpty(this.resourcePath.type)) {
        this.$message({
          message: "标题和资源类型不能为空！",
          type: "error"
        });
        return;
      }

      this.$http.post(this.$constant.baseURL + "/webInfo/" + (this.isUpdate ? "updateResourcePath" : "saveResourcePath"), this.resourcePath, true).then(function (res) {
        _this.$message({
          message: "保存成功！",
          type: "success"
        });

        _this.addResourcePathDialog = false;

        _this.clearDialog();

        _this.search();
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    search: function search() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getResourcePaths();
    },
    getResourcePaths: function getResourcePaths() {
      var _this2 = this;

      this.$http.post(this.$constant.baseURL + "/webInfo/listResourcePath", this.pagination, true).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.resourcePaths = res.data.records;
          _this2.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeStatus: function changeStatus(item) {
      var _this3 = this;

      this.$http.post(this.$constant.baseURL + "/webInfo/updateResourcePath", item, true).then(function (res) {
        _this3.$message({
          message: "修改成功！",
          type: "success"
        });
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getResourcePaths();
    },
    handleDelete: function handleDelete(item) {
      var _this4 = this;

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this4.$http.get(_this4.$constant.baseURL + "/webInfo/deleteResourcePath", {
          id: item.id
        }, true).then(function (res) {
          _this4.search();

          _this4.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this4.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    handleEdit: function handleEdit(item) {
      this.resourcePath = JSON.parse(JSON.stringify(item));
      this.addResourcePathDialog = true;
      this.isUpdate = true;
    },
    clearDialog: function clearDialog() {
      this.isUpdate = false;
      this.addResourcePathDialog = false;
      this.resourcePath = {
        title: "",
        classify: "",
        introduction: "",
        cover: "",
        url: "",
        type: "",
        remark: ""
      };
    }
  }
};